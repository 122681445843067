/* TelephoneEmailLink.css */
.contact-links {
  display: flex;
  flex-direction: column;
}

.contact-link {
  olor: lightblue;
  text-decoration: none;
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
}

.contact-link:hover,
.contact-link:focus {
  text-decoration: underline;
}
