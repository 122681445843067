footer {
  padding: 20px;
  font-family: "Roboto" Im !important;
}
.address {
  font-size: 16px;
  font-weight: bold;
  padding-top: 0;
  margin-top: 0;
}

.little-gray {
  font-size: 16px;
  color: #97d1ee;
  font-weight: bold;
  letter-spacing: 0rem;
}

.footer-link-item {
  display: block;
  text-decoration: none;
  margin-bottom: 10px;
}

.terms-section {
  border-top: 2px solid white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .terms-section {
    flex-direction: column;
  }
}

.terms-links {
  display: flex;
}

.terms-links a:first-of-type {
  margin-right: 10px;
}

.copyright {
  font-size: 16px;
}
