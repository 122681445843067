.text-columns-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 70px;
}

.text-column {
  flex: 1 1 30%;
  box-sizing: border-box;
  position: relative;
  min-width: 320px;
}

.text-column img {
  width: 100%;
}

.column-image-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  display: block;
  left: 0;
  width: 100%;
  height: 240px;
  z-index: -1; /* Ensure it is behind the content */
}

.headline-blue {
  font-size: 18px !important;
  letter-spacing: 1.5px !important;
  font-weight: 500;
  color: darkred;
  margin-bottom: 10px;
  text-transform: uppercase;
  padding: 0;
}

.flex-2 {
  display: flex;
}

.img-col-2 {
  background-image: url("../../public/serie9-gallery/9.png");
  background-size: cover;
  background-position: center;
  height: 700px;
}

.column-image {
  width: 100%;
}

.flexer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0px auto;
}

.flexer > div {
  border: 2px solid white;
  color: white;

  margin: 10px 0;
  border-radius: 4px;
  font-size: 18px;
  flex: 1 1 45%;
  max-width: 48%;
  cursor: pointer;
  position: relative;
  height: auto;
}

.flexer div .expandable-content {
  display: none;
  background-color: lightyellow;
  color: black;
  padding: 20px;

  margin-top: 15px;
}

.flexer div.expanded .expandable-content {
  display: block;
}

.flexer div.expanded {
  height: auto;
  padding: 10px;
}

.flexer div.closed {
  height: 65px;
  padding: 10px;
}

.flexer div .icon {
  margin-left: 10px;
}

@media (max-width: 720px) {
  .flexer div {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .bg {
    background-image: none !important;
  }
}

.bg {
  background-size: cover;
  background-position: center center;
}

.expandable-wrapper {
  overflow: hidden;
  transition: height 0.3s ease;
}

.expandable-wrapper.expanded {
  height: auto;
  max-height: 1000px; /* some large value to allow for auto height */
}

.expandable-content {
  padding: 10px;
}
