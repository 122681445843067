.navbar.scrolled {
  background-color: #141414; /* Change to your desired color */
  border-bottom: 2px solid white;
}

/* Other existing CSS styles */

.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  flex-wrap: wrap;
  z-index: 100;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
}

.logo {
  max-width: 180px; /* Adjust the max-width of your logo as needed */
  padding-left: 40px;
  scale: 1.25;
}

.phone-grid {
  display: grid;
  grid-template-columns: repeat(1, 55px 1fr);
  gap: 5px;
  align-items: center;
  min-width: 215px;
  margin-bottom: 12px;
  font-size: 14px;
}

.nav-grid {
  display: flex;
  align-items: center;
}

.btn {
  max-width: 10px;
}

.phone-tag {
  font-weight: bold;
  margin-bottom: 2px;
  display: block;
}

.phone-tag,
.phone-number {
  color: white;
}

.contact-button {
  max-width: 140x;
}

.contact-button button {
  font-size: 16px;
}

@media (max-width: 1000px) {
  .nav-grid,
  .contact-button {
    display: none;
  }
}

@media (max-width: 1000px) {
  .phone-grid {
    display: none;
  }
  .logo {
    padding-left: 20px;
    width: 160px;
  }
}

.menu-grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-gap: 20px;
}

.nav-grid .menu-grid .active {
  color: #97d1ee;
  font-weight: bold;
  border-bottom: 2px solid white; /* or any other style to highlight */
  border-radius: 0;
}
