/* Button.css */
.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  width: 200px;
}

.primary {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.secondary {
  background-color: #6c757d;
  color: white;
}

.button:hover {
  color: black;
}

.button:active {
  transform: translateY(1px);
}
