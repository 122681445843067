.avatar-flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  background-color: #3a0c0b;
  padding: 40px 0;
  padding-bottom: 80px;
}

.avatar-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  min-height: 500px;
}

.avatar-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 20px 0;
}

.avatar-image-container {
  background-image: url("../../public/tab.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  border: 2px solid lightyellow;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1100px) {
  .avatar-card {
    width: 33.3%;
    padding: 20px;
    align-items: space-around;
    text-align: left;
  }
}

.avatar-details {
  background: #2e4049;
  border: 2px solid lightyellow;
  color: lightyellow;
  padding: 20px;
  border-top: none;
  height: 100%;
}
