/* MobileMenu.css */
.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 6px;
}

.menu-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
  color: white;
  font-size: 40px;
}

.menu-icon.open {
  transform: rotate(180deg);
}

.menu-links {
  position: absolute;
  top: 90px;
  left: 0;

  width: 100%;
  background-color: #f8f9fa;
  padding: 0 20px;
  height: 0px;
  transition: transform 0.3s ease, opacity 0.3s ease, top 0.3s ease,
    height 0.3s ease;

  opacity: 1;

  background-color: #141414;
  color: white;
  overflow: hidden;

  font-size: 18px;
}

.menu-links-inner > a {
  margin-bottom: 40px;
  font-size: 20px;
  color: white;
  text-decoration: none;
}

.mobile-menu.open .menu-links {
  opacity: 1;
  top: 90px;
  height: 550px;
  left: 0;
  width: 100%;
}

.menu-links-inner {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

.phone-grid-2 {
  display: grid;
  grid-template-columns: repeat(1, 55px 1fr);
  gap: 5px;
  align-items: center;
  min-width: 215px;
  margin-top: 50px;
}

.phone-tag-2 {
  margin-bottom: 4px;
  display: block;
}

.phone-number-2 {
  font-weight: bold;
}

.no-scroll {
  overflow: hidden;
}

.spacer-left-5 {
  margin-left: 20px;
}
