.opening-hours {
  flex: 0 0 400px;
  min-width: 400px;
  background-color: #f0f0f0;
  padding: 50px;
  background-color: black;
  margin-bottom: 40px;
}

.map {
  flex: 1;
  background-color: #d0d0d0;
}

@media (max-width: 800px) {
  .opening-hours,
  .map {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100px;
    height: 400px;
    margin-top: 0px;
  }
  .opening-hours {
    margin-bottom: 260px;
    min-width: 320px;
    padding: 20px;
  }
}

.btn-anfahrt {
  margin: 40px;
  max-width: 400px;
  text-align: center;
  width: 100%;
  background: #ff9a01;
  color: black;
  border: none;
  padding: 20px;
  font-size: 16px;
}
