.pulsating-arrow {
  margin-top: 20px;
  display: inline-block;
  animation: pulse 2s infinite;
  cursor: pointer;
  color: #000; /* Adjust the color as needed */
  background-color: white;
  border-radius: 50%;
  padding: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
