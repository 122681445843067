html {
  scroll-padding-top: 140px; /* Adjust this value to match your fixed header height */
}
@media (max-width: 1000px) {
  html {
    scroll-padding-top: 100px; /* Adjust this value to match your fixed header height */
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", serif;
  background-color: black;
  color: white;
}

.background-container {
  background-image: url("/public/background-serie9-3.png");
  background-size: cover;
  background-position: bottom;
  height: 400px;
  width: 100%; /* Subtract the navbar height from the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.content {
  text-align: center;
  z-index: 1;
}

h1 {
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 42px !important;
  letter-spacing: 0rem !important;
}

.introduction {
  padding: 20px;
  background-color: black;
  color: white;
  font-family: "Roboto", sans-serif;
}
.introduction p {
  font-size: 32px !important;
  line-height: 2;
  text-align: center;
}

@media (max-width: 1000px) {
  .introduction p {
    font-size: 24px !important;
    line-height: 2;
  }
}

.gallery-image {
  width: 100%;
}

p {
  line-height: 1.75;
  font-size: 18px;
  font-family: "Fira Sans", serif;
}

ul {
  padding: 0;
}

ul li {
  list-style: none;
  border: 2px solid white;
  color: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 18px;
}

.check-icon {
  color: orangered;
  margin: 0 15px 0 5px;
}

.underline {
  background: #bd2828;
  opacity: 0.9;
  padding: 25px;
  display: block;
  font-weight: bold;
  text-align: center;
}

.container {
  margin: 0 auto;
}

.p-20 {
  padding: 20px;
}

.bold {
  font-weight: bold;
}

.email {
  color: #de2228;
  font-weight: bold;
}

.phone-tag {
  font-weight: bold;
  margin-bottom: 2px;
  display: block;
}

.phone-tag,
.phone-number {
  color: white;
}

.btn {
  max-width: 10px;
}

.login-container {
  text-align: center;
  padding-top: 100px;
  max-width: 720px;
  margin: 0 auto;
  padding: 20px;
}

.login-container button {
  margin-top: 40px;
}

.slick-prev:before,
.slick-next:before {
  color: #ff9a01;
  font-size: 60px;
  opacity: 0.85;
}

.slick-next {
  right: 60px !important;
}
