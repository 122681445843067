.introduction {
  background-image: url("../../public/wtm-hero-3.jpg");
  background-size: cover;
  background-position: top;
  color: white;
  padding: 20px;
  margin-top: -155px;
  align-items: center;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative; /* Add this to make sure the pseudo-element is positioned relative to .introduction */
}

@media (max-width: 670px) {
  .introduction {
    margin-top: -180px;
    background-image: url("../../public/wtm-hero-mobile-3.jpg");
  }
  .introduction .container {
    margin-top: 0 !important;
  }
}

.introduction::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Ensure the pseudo-element covers the entire height */

  z-index: 0; /* Place behind the content */
}

.introduction .container {
  border-radius: 10px; /* Optional: for rounded corners */
  color: black;
  position: relative; /* Ensure container content is above the pseudo-element */
  z-index: 1; /* Ensure the content is above the pseudo-element */
  color: white;
  margin-top: 250px;
}

.slick-slider {
  max-height: 600px; /* Set your desired max height */
  overflow: hidden; /* Ensures content beyond the max height is hidden */
}
