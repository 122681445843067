.privacy-policy {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 20px;
  color: white;
  background-color: black;
  font-size: 16px;
  margin: 0 auto;
  max-width: 720px;
}

.privacy-policy h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 34px !important;
}

.privacy-policy h2 {
  color: white;
  margin-top: 20px;
  font-size: 24px !important;
}

.privacy-policy h3 {
  color: white;
  margin-top: 15px;
  font-size: 20px !important;
}

.privacy-policy address {
  font-style: normal;
  margin-bottom: 15px;
}

.privacy-policy ul {
  list-style: disc inside;
  font-size: 16px;
}

.privacy-policy p {
  margin-bottom: 10px;
}
