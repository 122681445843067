.image-grid-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.image-grid {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.image-container {
  position: relative;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  height: 300px;
  background: black;
}

.wrapper {
  flex: 1 1 calc(25% - 20px); /* 25% width minus gap */
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.title {
  margin-bottom: 10px;
  font-size: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  transition: bottom 0.5s ease;
  width: 100%;
}

.title-inner {
  opacity: 0;
  height: 0;
}

/*.button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}*/

.image-container:hover .overlay {
  opacity: 1;
}

.image-container:hover .title-inner {
  opacity: 1;
  height: auto;
}

.image-container:hover .title {
  bottom: 15%;
}

.detail-panel {
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  transition: max-height 0.5s ease;
  overflow: hidden;
  max-height: 500px;
}

.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #dc3545;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .wrapper {
    flex: 1 1 calc(50% - 20px); /* 50% width minus gap */
  }
  .image-grid {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .wrapper {
    flex: 1 1 100%; /* 100% width */
  }

  .detail-panel {
    grid-column: 1 / -1;
  }
}

.image-container h3 {
  font-size: 24px;
}

.more {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.more-full {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.expanded {
  max-height: 1200px;
  margin-top: 20px;
  min-height: 400px;
}

.expanded h3 {
  margin-bottom: 10px;
}

.expanded-p {
  margin-bottom: 20px;
}
