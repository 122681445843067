.slick-slide {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide div {
  height: 100%;
}

.slick-slide img {
  height: 100%;
  object-fit: cover;
}

.slick-next {
  right: 25px;
}
.slick-prev {
  left: 25px;
}

.slick-next,
.slick-prev {
  z-index: 1;
}

.slick-dots {
  display: none !important;
}

.slick-slider,
.slick-track,
.slick-list {
  height: 100%;
}

.project-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-section > div {
  flex: 1 1 50%;
  width: 50%;
}

@media (max-width: 1000px) {
  .project-section > div {
    flex: 1 1 100%; /* Make items full width on small screens */
    max-width: 100%; /* Ensure full width on small screens */
  }
  .description {
    padding: 60px 20px !important;
  }
  .change-order {
    order: 2;
  }
}

.slider-container {
  min-height: 500px;
  max-height: 850px;
}
